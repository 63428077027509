import dynamic from 'next/dynamic';
import Lottie from 'react-lottie-player';

export const Animation404 = dynamic(
  Promise.resolve(() => {
    return (
      <div className="mx-auto lg:scale-150 lg:py-20">
        <Lottie animationData={require('./404.json')} loop play />
      </div>
    );
  }),
  { ssr: false },
);
