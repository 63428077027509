import { Button } from '@prishapolicy/shared/ui';
import { Animation404 } from '@prishapolicy/w3/ui';

export default function Custom404() {
  return (
    <div className="grid h-screen place-items-center">
      <div className="grid text-center">
        <div className="text-title-3 px-3 font-bold">Oops! Looks like you&apos;re lost</div>
        <Animation404 />
        <div className="relative m-auto">
          <Button text="Back To Home" className="z-30" onClick={() => (window.location.href = '/')} />
        </div>
      </div>
    </div>
  );
}
